<template>
    <div class="min-h-screen bg-gradient-to-br from-green-50 to-white py-10">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-extrabold text-green-700 mb-8 text-center border-b-4 border-green-500 pb-3">
          Liste des Clients avec Abonnements Multiples
        </h1>
  
        <section>
          <div v-if="clients.length" class="bg-white p-6 rounded-lg shadow-lg">
            <ul class="divide-y divide-gray-200">
              <li
                v-for="domain in clients"
                :key="domain.key"
                class="py-4 group transition duration-300 ease-in-out hover:bg-green-50"
              >
                <!-- Domain summary -->
                <div class="flex justify-between items-center">
                  <div>
                    <p class="font-semibold text-gray-800">Domaine : {{ domain.key }}</p>
                    <p class="text-sm text-gray-600">Nombre de clients : {{ domain.customers.length }}</p>
                  </div>
  
                  <div class="flex gap-3">
                    <button
                      @click="toggleDetails(domain.key)"
                      class="bg-green-500 text-white font-semibold py-2 px-4 rounded shadow hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-200"
                    >
                      {{ expandedClientId === domain.key ? 'Réduire' : 'Voir Plus' }}
                    </button>
                  </div>
                </div>
  
                <!-- Expanded domain details -->
                <div
                  v-if="expandedClientId === domain.key"
                  class="mt-4 p-6 bg-gray-100 rounded-lg shadow-inner transition-all duration-300"
                >
                  <h3 class="text-lg font-bold text-green-700 mb-4">Clients</h3>
                  <ul class="divide-y divide-gray-300 mb-4">
                    <li
                      v-for="client in domain.customers"
                      :key="client.id"
                      class="py-3"
                    >
                      <p class="font-semibold text-gray-800">Nom : {{ client.name }}</p>
                      <p class="text-sm text-gray-600">Email : {{ client.email }}</p>
                      <p class="text-sm text-gray-600">Nombre d'abonnements : {{ client.subscriptions.length }}</p>
                      <a
                        :href="client.link"
                        target="_blank"
                        rel="noopener noreferrer"
                        class="mt-5 bg-blue-500 text-white font-semibold py-2 px-4 rounded shadow hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-200"
                        >
                        Voir Stripe
                    </a>

                      <h4 class="text-md font-bold text-green-700 mt-3">Abonnements</h4>
                      <ul class="ml-4">
                        <li
                          v-for="subscription in client.subscriptions"
                          :key="subscription.id"
                          class="text-sm text-gray-600 mb-2"
                        >
                          - ID : {{ subscription.id }} ({{ subscription.status }})
                          <br />
                          Prochaine facturation :
                          {{ new Date(subscription.nextBillingDate * 1000).toLocaleDateString() }}
                        </li>
                      </ul>
                    </li>
                  </ul>
  
                  <h3 class="text-lg font-bold text-green-700 mb-4">Abonnements Combinés Globaux</h3>
                  <ul class="divide-y divide-gray-300">
                    <li
                      v-for="globalItem in domain.globalCombinedSubscription"
                      :key="globalItem.priceId"
                      class="py-3"
                    >
                      <p class="text-sm text-gray-600">
                        {{ globalItem.productName }} : {{ globalItem.quantity }} unités (ID : {{ globalItem.priceId }})
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div v-else class="text-gray-500 mt-4 text-center">Aucun client trouvé avec plusieurs abonnements.</div>
        </section>
  
        <!-- Floating Reload Button -->
        <button
          @click="fetchClients"
          class="fixed bottom-[50%] right-10 bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 transition duration-200"
        >
          <i class="fas fa-sync-alt"></i>
        </button>
      </div>
    </div>
  </template>
  
  
  <script>
    import { ref, onMounted } from "vue";
    import api from '../plugins/axios';
  
    export default {
      name: "MultipleClientList",
      setup() {
        const clients = ref([]);
        const expandedClientId = ref(null);
  
        const fetchClients = async () => {
          try {
            const response = await api.get("/admin2/multiple-payments");
            clients.value = response.data;
          } catch (error) {
            console.error("Erreur lors de la récupération des clients :", error);
          }
        };
  
        const toggleDetails = (clientId) => {
          expandedClientId.value =
            expandedClientId.value === clientId ? null : clientId;
        };
  
        onMounted(() => {
          fetchClients();
        });
  
        return {
          clients,
          expandedClientId,
          toggleDetails,
        };
      },
    };
  </script>
  