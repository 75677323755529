<template>
  <div class="flex h-screen bg-white">
    <div class="relative w-full md:w-1/3 flex flex-col justify-center items-center bg-white">
      <div class="login-element w-full max-w-md px-8 relative">
        <div class="mb-6">
          <img src="../assets/main-logo.png" alt="Logo Mirror Profiles" class="w-36 h-auto" />
        </div>
        <h2 class="login-title mb-6">Connexion</h2>

        <form @submit.prevent="login" class="space-y-6">
          <div>
            <label for="username" class="login-text">Adresse e-mail</label>
            <input
              v-model="email"
              id="username"
              type="email"
              placeholder="Entrez votre adresse e-mail"
              required
              class="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
            />
          </div>

          <div>
            <label for="password" class="login-text">Mot de passe</label>
            <input
              v-model="password"
              id="password"
              type="password"
              placeholder="Entrez votre mot de passe"
              required
              class="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
            />
          </div>

          

          <button
            type="submit"
            class="button-login w-full py-2 px-4 text-white font-semibold rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            Connexion
          </button>
        </form>

        <p v-if="errorMessage" class="text-red-600 text-center mt-4">{{ errorMessage }}</p>
      </div>
    </div>

    <div
      class="rounded-tl-[30px] hidden md:flex md:w-2/3 text-white relative"
      style="background: linear-gradient(184deg, #2ECC71 0.49%, #28A745 83.27%)"
    >
      <div class="flex flex-col w-[80%] mx-auto my-auto">
        
      </div>
    </div>
  </div>
</template>


<script>
import api from '../plugins/axios'; 
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'LoginView',

  setup() {
    const email = ref('');
    const password = ref('');
    const errorMessage = ref('');
    const router = useRouter();

    const login = async () => {
      try {
        const response = await api.post('/admin2/adminLogin', {
          email: email.value, 
          password: password.value,
        });
        console.log(response.data);

        const { token, adminId } = response.data;

        localStorage.setItem('token', token);
        localStorage.setItem('email', email.value);
        localStorage.setItem('nom', 'Admin');
        localStorage.setItem('prenom', adminId);

        router.push('/dashboard/stripe'); 
     
      } catch (error) {
        console.error('Erreur lors de la connexion :', error);
        errorMessage.value = 'Échec de la connexion. Vérifiez vos identifiants.';
      }
    };

    return {
      email,
      password,
      errorMessage,
      login,
    };
  },
};
</script>

