<template>
    <div class="flex flex-col sm:flex-row h-screen">
      <aside class="w-full sm:w-1/5 bg-green-700 text-white flex flex-col items-center p-4 sm:h-full profile-box" style="min-width: 300px; max-width: 300px; z-index: 999999999;">
        <img src="../assets/main-logo-white.png" alt="Mirror Profiles Logo" class="w-32 mb-8 mx-auto margin-top-30">
  
        <nav class="flex flex-col space-y-8 w-full items-center margin-top-30">
          <router-link to="stripe" class="flex items-center px-4 py-2 w-3/4 md:w-2/3 rounded nav-link" active-class="active-link">
            <i class="fas fa-credit-card mr-2"></i> Stripe
          </router-link>

          <router-link to="StripeMultiple" class="flex items-center px-4 py-2 w-3/4 md:w-2/3 rounded nav-link" active-class="active-link">
            <i class="fas fa-credit-card mr-2"></i> Stripe Views
          </router-link>
  
          <router-link to="monitoring" class="flex items-center px-4 py-2 w-3/4 md:w-2/3 rounded nav-link" active-class="active-link">
            <i class="fas fa-chart-line mr-2"></i> Monitoring
          </router-link>

        </nav>
  
        <div class="mt-auto mb-8 p-4 w-full">
          <hr class="my-2 border-t border-green-200 espace" />
          <div class="flex items-center justify-between flex-wrap md:flex-nowrap">
            <div class="flex-1 min-w-0 mb-2 md:mb-0">
              <p class="text-sm font-semibold truncate">{{ nom }} {{ prenom }}</p>
              <p class="text-xs truncate email">{{ email }}</p>
            </div>
            <button @click="logout" class="text-white flex-shrink-0 mt-2 md:mt-0">
              <i class="fas fa-power-off"></i>
            </button>
          </div>
        </div>
      </aside>
  
      <main class="flex-1 p-6 overflow-y-auto bg-white">
        <router-view />
      </main>
    </div>
  </template>
  
  <script>
  import axios from '../plugins/axios';
  
  export default {
    name: 'MainLayout',
    data() {
      return {
        nom: localStorage.getItem('nom') || '',
        prenom: localStorage.getItem('prenom') || '',
        email: localStorage.getItem('email') || '',
      };
    },
    computed: {
      selectedFlag() {
        return this.selectedLanguage === 'fr' ? 'https://flagcdn.com/16x12/fr.png' : 'https://flagcdn.com/16x12/gb.png';
      },
    },
    methods: {
      redirectToHelp() {
        const baseUrl = 'https://help.mirrorprofiles.com';
        const languagePath = this.selectedLanguage === 'fr' ? 'fr' : 'en';
        window.location.href = `${baseUrl}/${languagePath}/`;
      },
      async redirectToBillingPortal() {
        try {
          const response = await axios.get('/api/billing-portal');
          if (response.data.url) {
            window.location.href = response.data.url;
          } else {
            console.error('URL du portail de facturation non reçue.');
          }
        } catch (error) {
          console.error('Erreur lors de la redirection vers le portail de facturation :', error);
          alert("Une erreur s'est produite lors de la tentative d'accès au portail de facturation.");
        }
      },
 
      logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('nom');
        localStorage.removeItem('prenom');
        localStorage.removeItem('langue');
        this.$router.push('/login');
      },
    },
    mounted() {
    },
  };
  </script>
  
  <style scoped>
  .nav-link {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s ease, background-color 0.3s ease;
  }
  
  .nav-link:hover {
    background-color: #ffffff;
    color: #28A745;
    font-weight: 700;
  }
  
  .active-link {
    background-color: #ffffff;
    color: #28A745;
    font-weight: 700;
  }
  
  .espace {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .margin-top-30 {
    margin-top: 30px;
  }
  
  .email {
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    opacity: 0.7;
  }
  </style>
  